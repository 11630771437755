import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "../Logistics/Logistics.scss";
import ServiceToolbar from "../ServiceToolbar";

const Stevedoring = () => {
  return (
    <>
      <div className="logistics-con">
     <div>
      <p className="title">
        <StaticImage
          className="service-badge"
          src="../../../images/svg/arrastre-stevedoring-2.svg"
          width={45}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="badge"
        />
          Stevedoring & Arrastre
        </p>
     </div>

        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left">
            <div className="logistics">
              <StaticImage
                  className="ch-img"
                  src="../../../images/stevedoring.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text">
                {/* <p className="label">Stevedoring & Arrastre</p> */}
                <p>
                  With over 30 years experience in the handling of bulk cargo,
                  Subic Grain's methods not only ensures efficient discharging
                  of client's raw materials guaranteed via a 99.5% throughout
                  promise coupled by the turnover of any excess cargo acquired
                  during discharge operations, but also prioritises the vessel's
                  laytime stay within the SBMA's waters by accomplishing the task
                  at hand with the shortest amount of time possible.
                </p>
                <br/>

                {/* <p>
                  <b>Equipment:</b>
                  <ul>
                    <li>3x 180 MTPH rail-mounted mechanical Portal-Cranes</li>
                    <li>3x 60 MTPH Vac-U-Vator</li>
                  </ul>
                </p>

                <br/>
                <p>
                  <b>Vessels:</b>
                  <ul>
                    <li>Handysize (Geared and Gearless)</li>
                    <li>Handymax (Geared and Gearless)</li>
                    <li>Panamax</li>
                  </ul>
                </p> */}
              </div>

            </div>
          </div>
        </div>
      </div>

      <ServiceToolbar />
    </>
  );
};

export default Stevedoring;
